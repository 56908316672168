import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  IconButton,
  Typography,
} from '@material-ui/core';
import Chart from 'react-apexcharts';
import { useTheme } from '@material-ui/core/styles';
import { ApexOptions } from 'apexcharts';
import { useTranslation } from 'react-i18next';
import { getMapValue } from '../../types/MapValues';
import { ConversionTriggerTypeLabels } from '../../resources/Campaign';
import { ConversionStatsView } from '../../api/types/App/View/Analytics';
import { Download } from '@material-ui/icons';
import exportCsv from '../../utils/exportCsv';
import { format } from 'date-fns';

interface ConversionTypePieProp extends CardProps {
  data: ConversionStatsView,
  rangeStart: Date,
  rangeEnd: Date,
}

const ConversionTypePie = ({
  data,
  rangeStart,
  rangeEnd,
  ...other
}: ConversionTypePieProp) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    colors: Object.keys(data.conversion_by_type)
      .filter((type) => data.conversion_by_type[type] > 0)
      .map((type) => getMapValue(ConversionTriggerTypeLabels, type).color),
    dataLabels: {
      enabled: false,
    },
    labels: Object.keys(data.conversion_by_type)
      .filter((type) => data.conversion_by_type[type] > 0)
      .map((type) => t(getMapValue(ConversionTriggerTypeLabels, type).label)),
    legend: {
      fontSize: '14px',
      position: 'bottom',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.subtitle2.fontWeight,
      itemMargin: {
      },
      labels: {
        colors: theme.palette.text.primary,
      },
      markers: {
        width: 8,
        height: 8,
      },
      show: true,
    },
    stroke: {
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      theme: theme.palette.mode,
      fillSeriesColor: false,
    },
    noData: {
      text: t('No conversions')
    }
  };

  const chartSeries = Object.values(data.conversion_by_type).filter((val) => val > 0);

  const exportCsvAction = () => {
    const csvData = Object.keys(data.conversion_by_type).map((type) => ({
      type: t(getMapValue(ConversionTriggerTypeLabels, type).label),
      count: data.conversion_by_type[type],
    }));

    exportCsv(`conversion_type_${format(rangeStart, 'yyyy-MM-dd--HH-mm-ss')}_${format(rangeEnd, 'yyyy-MM-dd--HH-mm-ss')}.csv`, csvData);
  };

  return (
    <Card {...other} sx={{ height: '100%' }}>
      <CardHeader
        disableTypography
        title={(
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              {t('Conversion Types')}
            </Typography>
          </Box>
        )}
        action={(
          <IconButton title={t('Download as CSV')} onClick={exportCsvAction}>
            <Download fontSize="small" />
          </IconButton>
        )}
      />
      <CardContent>
        <Box>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="donut"
            height="250"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default ConversionTypePie;

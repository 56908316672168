import {
  endOfMonth,
  endOfYear,
  endOfYesterday,
  fromUnixTime,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';

export const DailyTypeLabels = [
  {
    value: 'conversion',
    label: 'Conversions',
    color: '#FF1744',
  },
  {
    value: 'click_out',
    label: 'Click outs',
    color: '#FFEA00',
  },
  {
    value: 'unique_visit',
    label: 'Unique Visits',
    color: '#AA00FF',
  },
  {
    value: 'unique_visit_ip',
    label: 'Unique IP Visits',
    color: '#25f900',
  },
  {
    value: 'total_visit',
    label: 'Total Visits',
    color: '#18FFFF',
  },
];

export interface DateRangeType {
  label: string,
  value: string,
  start?: () => Date,
  end?: () => Date,
  format?: string,
}

export const DateRangeTypes: DateRangeType[] = [
  {
    label: 'Today',
    value: 'today',
    start: () => startOfToday(),
    end: () => new Date(),
    format: 'dd MMM hh:ii',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
    start: () => startOfYesterday(),
    end: () => endOfYesterday(),
    format: 'dd MMM hh:ii',
  },
  {
    label: 'Last 7 Days',
    value: 'l7d',
    start: () => subDays(startOfToday(), 7),
    end: () => new Date(),
  },
  {
    label: 'Last 30 Days',
    value: 'l30d',
    start: () => subDays(startOfToday(), 30),
    end: () => new Date(),
  },
  {
    label: 'Last 90 Days',
    value: 'l90d',
    start: () => subDays(startOfToday(), 90),
    end: () => new Date(),
  },
  {
    label: 'Last month',
    value: 'lm',
    start: () => startOfMonth(subMonths(startOfToday(), 1)),
    end: () => endOfMonth(subMonths(startOfToday(), 1)),
  },
  {
    label: 'Last year',
    value: 'ly',
    start: () => startOfYear(subYears(startOfToday(), 1)),
    end: () => endOfYear(subYears(startOfToday(), 1)),
    format: 'dd MMM yyyy',
  },
  {
    label: 'Week to date',
    value: 'wtd',
    start: () => startOfWeek(startOfToday()),
    end: () => new Date(),
  },
  {
    label: 'Month to date',
    value: 'mtd',
    start: () => startOfMonth(startOfToday()),
    end: () => new Date(),
  },
  {
    label: 'Quarter to date',
    value: 'qtd',
    start: () => startOfQuarter(startOfToday()),
    end: () => new Date(),
  },
  {
    label: 'Year to date',
    value: 'ytd',
    start: () => startOfYear(startOfToday()),
    end: () => new Date(),
    format: 'dd MMM yyyy',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];

export function parseDate(date: string): Date {
  return fromUnixTime(Number(date));
}

export function getDailyType(value: string) {
  return DailyTypeLabels.find((l) => l.value === value);
}

export function getDateRange(value: string): DateRangeType {
  return DateRangeTypes.find((t) => t.value === value);
}

import { format, fromUnixTime, parse } from 'date-fns';
import { RangeDataView } from '../api/types/App/View/Common';

export interface RangeData<T> {
  date_range: {
    start: number;
    end: number;
    interval: string;
  };
  data: [number, T][];
  totals: T;
}

export interface RangeDataSeriesTimestampedData {
  total: number;
  data: [Date, number][];
}

export interface RangeDataSeriesData {
  total: number;
  data: number[];
}

export function newGetRangeDataSeriesTimestampedData<
  T extends { [key: string]: any },
  F extends keyof T
>(data: RangeDataView<T>, field: F): RangeDataSeriesTimestampedData {
  return {
    total: data.totals[field] ?? 0,
    data: data.data.map(([timestamp, value]) => {
      let date;
      if (data.range_interval === 'P1H') {
        date = format(fromUnixTime(timestamp), 'yyyy-MM-dd HH:00:00');
      } else {
        date = format(fromUnixTime(timestamp), 'yyyy-MM-dd 00:00:00');
      }

      return [
        parse(date, 'yyyy-MM-dd HH:mm:ss', new Date()),
        value[field] ?? 0,
      ];
    }),
  };
}

export function getRangeDataSeriesData<
  T extends { [key: string]: any },
  F extends keyof T
>(data: RangeData<T>, field: F): RangeDataSeriesData {
  return {
    total: data.totals[field] ?? 0,
    data: data.data.map(([, value]) => value[field] ?? 0),
  };
}

export function newGetRangeDataSeriesData<
  T extends { [key: string]: any },
  F extends keyof T
>(data: RangeDataView<T>, field: F): RangeDataSeriesData {
  return {
    total: data.totals[field] ?? 0,
    data: data.data.map(([, value]) => value[field] ?? 0),
  };
}

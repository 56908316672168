import React from 'react';
import useLoadApiData from '../../hooks/useLoadApiData';
import {getDailyType} from '../../types/TenantAnalyticsType';
import {fromUnixTime, getUnixTime} from 'date-fns';
import DateRangeGraph, {DateRangeGraphDataSeries} from './DateRangeGraph';
import {newGetRangeDataSeriesData, newGetRangeDataSeriesTimestampedData,} from '../../types/Analytics';
import {Box, Card, Divider, Grid, Typography} from '@material-ui/core';
import {formatDate} from '../../utils/timestampUtils';
import {useTranslation} from 'react-i18next';
import {GridSize} from '@material-ui/core/Grid/Grid';
import SmallLineChart from './SmallLineChart';
import numeral from '../../lib/numeral';
import {CampaignAnalyticsView} from '../../api/types/App/View/Campaign';
import {ApiEndpointType, NoBody} from '../../api/client';
import ConversionTypePie from './ConversionTypePie';
import {formatMoneyValue} from '../../types/Money';
import useAuth from '../../hooks/useAuth';
import {BaseTenantContractView} from "../../api/types/App/View/TenantContract";
import useLoadApiSelectorData from "../../hooks/useLoadApiSelectorData";
import {contractTenantUserSelector} from "../../api/endpoints/Api/Tenants/Contracts";
import DataGridSelect, {DataGridSelectOptions} from "../DataGrid/Select";
import i18next from "i18next";
import {ContractAnalyticsRequestParams} from "../../api/types/App/Request/TenantContract";

interface ContractAnalyticsProps {
    contract: BaseTenantContractView,
    endpoint: ApiEndpointType<CampaignAnalyticsView, ContractAnalyticsRequestParams, NoBody, any, any>,
}

const ContractAnalytics = (props: ContractAnalyticsProps) => {
    const {
        contract,
        endpoint,
    } = props;
    const {t} = useTranslation();
    const {currentDashboard} = useAuth();
    const [endAt] = React.useState<Date>(new Date());
    const [currentParticipant, setCurrentParticipant] = React.useState<string>('all');

    const {apiData: analyticsData} = useLoadApiData(endpoint)({
        tenantId: currentDashboard.id,
        id: contract.id,
    }, {
        start: contract.start_at,
        end: getUnixTime(endAt),
        tenant_user: currentParticipant === 'all' ? undefined : currentParticipant,
    });

    const {apiData: tenantUsers} = useLoadApiSelectorData(contractTenantUserSelector)({
        tenantId: currentDashboard.id,
        id: contract.id,
    });

    if (analyticsData === null) {
        return null;
    }

    const conversionData: DateRangeGraphDataSeries[] = [
        {
            label: 'Conversions',
            color: getDailyType('conversion').color,
            seriesData: newGetRangeDataSeriesTimestampedData(analyticsData.conversions.range_data, 'conversions'),
        },
    ];

    const clickOutData: DateRangeGraphDataSeries[] = [
        {
            label: 'Click Outs',
            color: getDailyType('click_out').color,
            seriesData: newGetRangeDataSeriesTimestampedData(analyticsData.conversions.range_data, 'click_outs'),
        },
    ];

    const totalVisitData: DateRangeGraphDataSeries[] = [
        {
            label: 'Total Visit',
            color: getDailyType('total_visit').color,
            seriesData: newGetRangeDataSeriesTimestampedData(analyticsData.conversions.range_data, 'total_visits'),
        },
    ];

    const daily = {
        clickOut: newGetRangeDataSeriesData(analyticsData.conversions.range_data, 'click_outs'),
        conversion: newGetRangeDataSeriesData(analyticsData.conversions.range_data, 'conversions'),
        totalVisits: newGetRangeDataSeriesData(analyticsData.conversions.range_data, 'total_visits'),
    };

    const gridValues: Record<string, boolean | GridSize> = {
        md: 3,
        sm: 6,
        xs: 12,
    };

    const userSelectConfig: DataGridSelectOptions = {
        label: 'Participant',
        values: [
            {
                label: i18next.t('All'),
                value: 'all',
            },
            ...tenantUsers?.values ?? [],
        ],
        current: currentParticipant,
        onChange: setCurrentParticipant,
        translateValues: false,
        containerProps: (p) => {
            delete p.sx;

            return p;
        }
    };

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        color="textPrimary"
                        variant="h5"
                    >
                        {`${t('Analytics')} ${t('for')} (${formatDate(fromUnixTime(contract.start_at), 'PP')} - ${formatDate(endAt, 'PP')})`}
                    </Typography>
                </Grid>
                <Grid item>
                    <DataGridSelect config={userSelectConfig}/>
                </Grid>
            </Grid>
            <Grid
                sx={{py: 3}}
                container
                spacing={2}
            >
                <Grid item {...gridValues}>
                    <Card sx={{height: '100%'}}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: 3,
                            }}
                        >
                            <div>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    {t('Click Outs')}
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    sx={{mt: 1}}
                                    variant="h4"
                                >
                                    {numeral(daily.clickOut.total)
                                        .format('0,0')}
                                </Typography>
                            </div>
                            <SmallLineChart data={daily.clickOut.data} color={getDailyType('click_out').color}/>
                        </Box>
                        <Divider/>
                        <Box
                            sx={{
                                px: 3,
                                py: 2,
                            }}
                        >
                            <Box/>
                        </Box>
                    </Card>
                </Grid>
                <Grid item {...gridValues}>
                    <Card sx={{height: '100%'}}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: 3,
                            }}
                        >
                            <div>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    {t('Total Visits')}
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    sx={{mt: 1}}
                                    variant="h4"
                                >
                                    {numeral(daily.totalVisits.total)
                                        .format('0,0')}
                                </Typography>
                            </div>
                            <SmallLineChart
                                data={daily.totalVisits.data}
                                color={getDailyType('total_visit').color}
                            />
                        </Box>
                        <Divider/>
                        <Box
                            sx={{
                                px: 3,
                                py: 2,
                            }}
                        >
                            <Box/>
                        </Box>
                    </Card>
                </Grid>
                <Grid item {...gridValues}>
                    <Card sx={{height: '100%'}}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: 3,
                            }}
                        >
                            <div>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    {t('Conversions')}
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    sx={{mt: 1}}
                                    variant="h4"
                                >
                                    {numeral(daily.conversion.total)
                                        .format('0,0')}
                                </Typography>
                            </div>
                            <SmallLineChart
                                data={daily.conversion.data}
                                color={getDailyType('conversion').color}
                            />
                        </Box>
                        <Divider/>
                        <Box
                            sx={{
                                px: 3,
                                py: 2,
                            }}
                        >
                            <Box/>
                        </Box>
                    </Card>
                </Grid>
                <Grid item {...gridValues}>
                    <Card sx={{height: '100%'}}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: 3,
                            }}
                        >
                            <div>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    {t('Total Payout')}
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    sx={{mt: 1}}
                                    variant="h4"
                                >
                                    {formatMoneyValue(analyticsData.payouts.total_amount)}

                                </Typography>
                            </div>
                            <SmallLineChart
                                data={[]}
                                color={getDailyType('unique_visit').color}
                            />
                        </Box>
                        <Divider/>
                        <Box
                            sx={{
                                px: 3,
                                py: 2,
                            }}
                        >
                            <Box/>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    md={9}
                    xl={10}
                    xs={12}
                >

                    <Grid
                        container
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={12} lg={6} xl={4}>
                            <DateRangeGraph
                                series={conversionData}
                                title="Conversions"
                                height={200}
                                interval={analyticsData.conversions.range_data.range_interval}
                                rangeStart={fromUnixTime(contract.start_at)}
                                rangeEnd={endAt}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={4}>
                            <DateRangeGraph
                                series={clickOutData}
                                title="Click Outs"
                                height={200}
                                interval={analyticsData.conversions.range_data.range_interval}
                                rangeStart={fromUnixTime(contract.start_at)}
                                rangeEnd={endAt}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={4}>
                            <DateRangeGraph
                                series={totalVisitData}
                                title="Total Visits"
                                height={200}
                                interval={analyticsData.conversions.range_data.range_interval}
                                rangeStart={fromUnixTime(contract.start_at)}
                                rangeEnd={endAt}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    md={3}
                    xl={2}
                    xs={12}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ConversionTypePie
                                data={analyticsData.conversions}
                                rangeStart={fromUnixTime(contract.start_at)}
                                rangeEnd={endAt}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ContractAnalytics;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardProps,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { RangeDataSeriesTimestampedData } from '../../types/Analytics';
import _, { snakeCase } from 'lodash';
import ApexChartsLocaleSettings from '../../i18n/ApexChartsLocaleSettings';
import { Download } from '@material-ui/icons';
import { format } from 'date-fns';
import exportCsv from '../../utils/exportCsv';

interface DateRangeGraphProps extends CardProps {
  series: DateRangeGraphDataSeries[],
  title: string,
  height: number,
  interval: string,
  rangeStart: Date,
  rangeEnd: Date,
}

export interface DateRangeGraphDataSeries {
  color: string,
  label: string,
  seriesData: RangeDataSeriesTimestampedData
}

const DateRangeGraph = (
  {
    series,
    title,
    height,
    interval,
    rangeStart,
    rangeEnd,
    ...other
  }: DateRangeGraphProps,
) => {
  const {
    t,
    i18n,
  } = useTranslation();
  const theme = useTheme();
  const seriesLabels = series.map((type) => type.label);
  const [selectedSeries, setSelectedSeries] = useState<string[]>(seriesLabels);
  const [chartId] = useState<string>(_.uniqueId('apexChart-'));

  const handleChange = (event, name: string) => {
    if (!event.target.checked) {
      setSelectedSeries(selectedSeries.filter((item) => item !== name));
    } else {
      setSelectedSeries([...selectedSeries, name]);
    }
  };

  useEffect(() => {
    ApexCharts.exec(chartId, 'setLocale', i18n.language);
  }, [i18n.language]);

  const chartSeries = series.filter((s) => selectedSeries.indexOf(s.label) !== -1)
    .map((s) => ({
      name: s.label,
      data: s.seriesData.data,
    }));

  const exportCsvAction = () => {
    const data = [];

    series.filter((s) => selectedSeries.indexOf(s.label) !== -1)
      .forEach((s) => {
        s.seriesData.data.forEach((sd) => {
          data.push({
            name: s.label,
            date: format(sd[0], 'yyyy-MM-dd HH:mm:ss'),
            value: sd[1],
          });
        });
      });

    exportCsv(`${snakeCase(title)}_${format(rangeStart, 'yyyy-MM-dd--HH-mm-ss')}_${format(rangeEnd, 'yyyy-MM-dd--HH-mm-ss')}.csv`, data);
  };

  const chartOptions: ApexOptions = {
    chart: {
      id: chartId,
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      ...ApexChartsLocaleSettings(),
      defaultLocale: i18n.language,
    },
    colors: series.map((item) => item.color),
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    markers: {
      hover: {
        size: undefined,
        sizeOffset: 2,
      },
      radius: 2,
      shape: 'circle',
      size: 4,
      strokeWidth: 0,
    },
    stroke: {
      curve: 'smooth',
      lineCap: 'butt',
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
        datetimeFormatter: {
          year: 'yyyy',
          month: interval === 'P1D' ? 'dd MMM' : 'MMM yy',
          day: 'dd MMM',
          hour: 'HH:mm',
        },
        datetimeUTC: false,
      },
      type: 'datetime',
    },
    yaxis: [
      {
        axisBorder: {
          color: theme.palette.divider,
          show: true,
        },
        axisTicks: {
          color: theme.palette.divider,
          show: true,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
      {
        axisTicks: {
          color: theme.palette.divider,
          show: true,
        },
        axisBorder: {
          color: theme.palette.divider,
          show: true,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
        opposite: true,
      },
    ],
  };

  return (
    <Card {...other} sx={{ height: '100%' }}>
      <CardHeader
        sx={{
          pb: 0,
        }}
        disableTypography
        title={(
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              {t(title)}
            </Typography>

            <IconButton title={t('Download as CSV')} onClick={exportCsvAction}>
              <Download fontSize="small" />
            </IconButton>

            {series.length > 1 && (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                  px: 2,
                }}
              >
                {series.map((item) => (
                  <Box
                    key={item.label}
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      mr: 1,
                    }}
                  >
                    <Checkbox
                      size="small"
                      checked={selectedSeries.some((visibleItem) => visibleItem === item.label)}
                      color="primary"
                      onChange={(event) => handleChange(event, item.label)}
                    />
                    <Tooltip title={t(item.label)}>
                      <Box
                        sx={{
                          backgroundColor: item.color,
                          borderRadius: '50%',
                          height: 8,
                          ml: 1,
                          mr: 1,
                          width: 8,
                        }}
                      />
                    </Tooltip>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}
      />
      <Chart
        height={height}
        options={chartOptions}
        series={chartSeries}
        type="line"
      />
    </Card>
  );
};

export default DateRangeGraph;

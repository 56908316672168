import React from 'react';
import Page, { PageProps } from '../../../components/Page';
import useAuth from '../../../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import { DashboardType } from '../../../redux/slices/User';
import {analytics, show, users} from '../../../api/endpoints/Api/Tenants/Contracts';
import useLoadApiData from '../../../hooks/useLoadApiData';
import { useParams } from 'react-router';
import { ContentView } from '../../../components/ContentSwitcher';
import { Box, Grid } from '@material-ui/core';
import Description from '../../../components/Contract/Description';
import AffiliateGroups from '../../../components/Contract/AffiliateGroups';
import ContractSettings from '../../../components/Contract/Settings';
import MapValueLabel from '../../../components/MapValueLabel';
import {
  AcceptAction,
  DeleteAction,
  EditAction,
  getContractStatus,
  getContractStatusText,
} from '../../../resources/TenantContract';
import Conditions from '../../../components/Contract/Conditions';
import ListResourceTable from '../../../components/ListResourceTable';
import { ListTenantUserColumns } from '../../../resources/TenantUser';
import { FullTenantContractView } from '../../../api/types/App/View/TenantContract';
import ContractAnalytics from "../../../components/Analytics/ContractAnalytics";

const ShowContractPage = () => {
  const { currentDashboard } = useAuth();
  const { id } = useParams<{ id: string }>();

  if (
    DashboardType.UserTenantAdmin !== currentDashboard.type &&
    DashboardType.TenantAdmin !== currentDashboard.type
  ) {
    return <Redirect to="/" />;
  }

  const { apiData, loadApiData } = useLoadApiData(show)({
    id,
    tenantId: currentDashboard.id,
  });

  if (!apiData) {
    return null;
  }

  let pageTitle = 'Contract "{{title}}"';
  if (apiData.destination !== null) {
    pageTitle = 'Contract "{{title}}" for {{client}}';
  }

  const pageProps: PageProps<FullTenantContractView> = {
    pageHeaderProps: {
      title: pageTitle,
      translationParams: {
        title: apiData.title,
        client: apiData.destination?.client?.company_name,
      },
      actions: [
        AcceptAction(currentDashboard)(loadApiData),
        EditAction(currentDashboard),
        DeleteAction(currentDashboard)(),
      ],
      subHeader:
        ((apiData.destination?.id === currentDashboard.id ||
          apiData.tenant.id === currentDashboard.id) &&
          (() => (
            <Box
              sx={{
                alignItems: 'center',
                color: 'text.secondary',
                display: 'flex',
                flexWrap: 'wrap',
                mb: -2,
                mx: -2,
              }}
            >
              <MapValueLabel value={getContractStatus(apiData)} />
              <MapValueLabel value={getContractStatusText(apiData)} />
            </Box>
          ))) ||
        undefined,
    },
    pageTabs: {
      tabs: [
        {
          value: 'Overview',
        },
        {
          value: 'Affiliates',
          hidden: (ctx) =>
            ctx.destination?.id !== currentDashboard.id &&
            ctx.tenant.id !== currentDashboard.id,
        },
        {
          value: 'Analytics',
          hidden: (ctx) =>
            ctx.destination?.id !== currentDashboard.id &&
            ctx.tenant.id !== currentDashboard.id,
        },
      ],
    },
    title: pageTitle,
    titleProps: {
      title: apiData.title,
      client: apiData.destination?.client?.company_name,
    },
    context: apiData,
  };

  return (
    <Page {...pageProps}>
      <ContentView value="Overview">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8} xl={9}>
            <Description text={apiData.description} />
            <Conditions contract={apiData} />
            {currentDashboard.type === DashboardType.UserTenantAdmin && (
              <>
                <AffiliateGroups contract={apiData} />
              </>
            )}
          </Grid>
          <Grid item lg={4} xl={3} xs={12}>
            <ContractSettings contract={apiData} />
          </Grid>
        </Grid>
      </ContentView>
      <ContentView value="Affiliates">
        <ListResourceTable
          endpoint={users}
          field="tenant_users"
          routeParams={{ tenantId: apiData.tenant.id, id: apiData.id }}
          params={{}}
          columns={ListTenantUserColumns('/affiliates', false, false)}
        />
      </ContentView>
      <ContentView value="Analytics">
        <ContractAnalytics contract={apiData} endpoint={analytics}/>
      </ContentView>
    </Page>
  );
};

export default ShowContractPage;

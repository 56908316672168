import ja from 'apexcharts/dist/locales/ja.json';
import en from 'apexcharts/dist/locales/en.json';
import i18next from 'i18next';

const apexLocales = [
  en,
  ja,
];

const ApexChartsLocaleSettings = () => ({
  locales: [...apexLocales],
  defaultLocale: i18next.language,
});

export default ApexChartsLocaleSettings;

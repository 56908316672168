import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import _ from 'lodash';

interface SmallLineChartProps extends React.ComponentProps<typeof Chart> {
  data: number[],
  color: string,
  apexOptions?: ApexOptions,
}

const SmallLineChart = (
  {
    data,
    color,
    apexOptions = {},
    type = 'line',
    ...rest
  }: SmallLineChartProps,
) => {
  const theme = useTheme();

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [color],
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    stroke: {
      width: [3],
    },
    theme: {
      mode: theme.palette.mode,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
  };

  const chartSeries = [{ data }];

  return (
    <Chart
      options={_.merge(chartOptions, apexOptions)}
      series={chartSeries}
      width={150}
      type={type}
      {...rest}
    />
  );
};

export default SmallLineChart;
